var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"rate","avatar":"dollar","suppliers":_vm.suppliers,"features":{
    search: false,
    create: true,
    update: true,
    delete: true,
  }},scopedSlots:_vm._u([{key:"row",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('h5',[_vm._v(_vm._s(_vm._f("currency")(item.value))+" "+_vm._s(item.currency))]),_c('j-label',{attrs:{"icon":"number"}},[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('j-label',{attrs:{"icon":"dollar"}},[_vm._v(" "+_vm._s(_vm.$t('zone'))+" "+_vm._s(item.code)+" ")]),_c('j-label',{attrs:{"icon":"star"}},[_vm._v(" "+_vm._s(_vm.$t(("enum.method." + (item.method))))+" ")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }