<template>
  <j-abm
    name="rate"
    avatar="dollar"
    :suppliers="suppliers"
    :features="{
      search: false,
      create: true,
      update: true,
      delete: true,
    }">
    <template #row="{item}">
      <div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <h5>{{ item.value | currency }} {{ item.currency }}</h5>
              <j-label icon="number">
                {{ item.id }}
              </j-label>
            </div>
            <div class="col-12 col-md-6">
              <j-label icon="dollar">
                {{ $t('zone') }} {{ item.code }}
              </j-label>
              <j-label icon="star">
                {{ $t(`enum.method.${item.method}`) }}
              </j-label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import { activation } from '@/utils/styles'
import JAbm from '@/views/shared/abm/search'
import JLabel from '@/views/shared/labels/text'
export default {
  components: {
    JAbm,
    JLabel,
  },
  data: function () {
    return {
      acl,
      activation,
    }
  },
  computed: {
    account() {
      return this.$route.params.account
    },
    suppliers() {
      return {
        fetchAll: page => lms.account.fetchRates(this.account, page),
      }
    },
  },
}
</script>
